const footerExploreLinks: Record<string, any> = {
  invideo: {
    en: {
      links: [
        {
          name: 'Online Video Editor',
          link: 'https://invideo.io/make/online-video-editor/',
        },
        {
          name: 'Video Collage Maker',
          link: 'https://invideo.io/make/video-collage-maker/',
        },
        {
          name: 'Picture Video Maker',
          link: 'https://invideo.io/make/picture-video-maker/',
        },
        {
          name: 'Instagram Video Editor',
          link: 'https://invideo.io/make/instagram-video-editor/',
        },
        {
          name: 'Online Video Maker',
          link: 'https://invideo.io/make/video-maker/',
        },
        {
          name: 'Facebook Video Editor',
          link: 'https://invideo.io/make/facebook-video-editor/',
        },
        {
          name: 'Add Music to Video',
          link: 'https://invideo.io/make/add-music-to-video-online/',
        },
        {
          name: 'Add Text to GIF',
          link: 'https://invideo.io/make/add-text-to-gif/',
        },
      ],
    },
    es: {
      links: [
        {
          name: 'Online Video Editor',
          link: 'https://invideo.io/make/online-video-editor/',
        },
        {
          name: 'Video Collage Maker',
          link: 'https://invideo.io/make/video-collage-maker/',
        },
        {
          name: 'Picture Video Maker',
          link: 'https://invideo.io/make/picture-video-maker/',
        },
        {
          name: 'Instagram Video Editor',
          link: 'https://invideo.io/make/instagram-video-editor/',
        },
        {
          name: 'Online Video Maker',
          link: 'https://invideo.io/make/video-maker/',
        },
        {
          name: 'Facebook Video Editor',
          link: 'https://invideo.io/make/facebook-video-editor/',
        },
        {
          name: 'Add Music to Video',
          link: 'https://invideo.io/make/add-music-to-video-online/',
        },
        {
          name: 'Add Text to GIF',
          link: 'https://invideo.io/make/add-text-to-gif/',
        },
      ],
    },
    fr: {
      links: [
        {
          name: 'Online Video Editor',
          link: 'https://invideo.io/make/online-video-editor/',
        },
        {
          name: 'Video Collage Maker',
          link: 'https://invideo.io/make/video-collage-maker/',
        },
        {
          name: 'Picture Video Maker',
          link: 'https://invideo.io/make/picture-video-maker/',
        },
        {
          name: 'Instagram Video Editor',
          link: 'https://invideo.io/make/instagram-video-editor/',
        },
        {
          name: 'Online Video Maker',
          link: 'https://invideo.io/make/video-maker/',
        },
        {
          name: 'Facebook Video Editor',
          link: 'https://invideo.io/make/facebook-video-editor/',
        },
        {
          name: 'Add Music to Video',
          link: 'https://invideo.io/make/add-music-to-video-online/',
        },
        {
          name: 'Add Text to GIF',
          link: 'https://invideo.io/make/add-text-to-gif/',
        },
      ],
    },
    id: {
      links: [
        {
          name: 'Online Video Editor',
          link: 'https://invideo.io/make/online-video-editor/',
        },
        {
          name: 'Video Collage Maker',
          link: 'https://invideo.io/make/video-collage-maker/',
        },
        {
          name: 'Picture Video Maker',
          link: 'https://invideo.io/make/picture-video-maker/',
        },
        {
          name: 'Instagram Video Editor',
          link: 'https://invideo.io/make/instagram-video-editor/',
        },
        {
          name: 'Online Video Maker',
          link: 'https://invideo.io/make/video-maker/',
        },
        {
          name: 'Facebook Video Editor',
          link: 'https://invideo.io/make/facebook-video-editor/',
        },
        {
          name: 'Add Music to Video',
          link: 'https://invideo.io/make/add-music-to-video-online/',
        },
        {
          name: 'Add Text to GIF',
          link: 'https://invideo.io/make/add-text-to-gif/',
        },
      ],
    },
    it: {
      links: [
        {
          name: 'Online Video Editor',
          link: 'https://invideo.io/make/online-video-editor/',
        },
        {
          name: 'Video Collage Maker',
          link: 'https://invideo.io/make/video-collage-maker/',
        },
        {
          name: 'Picture Video Maker',
          link: 'https://invideo.io/make/picture-video-maker/',
        },
        {
          name: 'Instagram Video Editor',
          link: 'https://invideo.io/make/instagram-video-editor/',
        },
        {
          name: 'Online Video Maker',
          link: 'https://invideo.io/make/video-maker/',
        },
        {
          name: 'Facebook Video Editor',
          link: 'https://invideo.io/make/facebook-video-editor/',
        },
        {
          name: 'Add Music to Video',
          link: 'https://invideo.io/make/add-music-to-video-online/',
        },
        {
          name: 'Add Text to GIF',
          link: 'https://invideo.io/make/add-text-to-gif/',
        },
      ],
    },
    pt: {
      links: [
        {
          name: 'Online Video Editor',
          link: 'https://invideo.io/make/online-video-editor/',
        },
        {
          name: 'Video Collage Maker',
          link: 'https://invideo.io/make/video-collage-maker/',
        },
        {
          name: 'Picture Video Maker',
          link: 'https://invideo.io/make/picture-video-maker/',
        },
        {
          name: 'Instagram Video Editor',
          link: 'https://invideo.io/make/instagram-video-editor/',
        },
        {
          name: 'Online Video Maker',
          link: 'https://invideo.io/make/video-maker/',
        },
        {
          name: 'Facebook Video Editor',
          link: 'https://invideo.io/make/facebook-video-editor/',
        },
        {
          name: 'Add Music to Video',
          link: 'https://invideo.io/make/add-music-to-video-online/',
        },
        {
          name: 'Add Text to GIF',
          link: 'https://invideo.io/make/add-text-to-gif/',
        },
      ],
    },
    ru: {
      links: [
        {
          name: 'Online Video Editor',
          link: 'https://invideo.io/make/online-video-editor/',
        },
        {
          name: 'Video Collage Maker',
          link: 'https://invideo.io/make/video-collage-maker/',
        },
        {
          name: 'Picture Video Maker',
          link: 'https://invideo.io/make/picture-video-maker/',
        },
        {
          name: 'Instagram Video Editor',
          link: 'https://invideo.io/make/instagram-video-editor/',
        },
        {
          name: 'Online Video Maker',
          link: 'https://invideo.io/make/video-maker/',
        },
        {
          name: 'Facebook Video Editor',
          link: 'https://invideo.io/make/facebook-video-editor/',
        },
        {
          name: 'Add Music to Video',
          link: 'https://invideo.io/make/add-music-to-video-online/',
        },
        {
          name: 'Add Text to GIF',
          link: 'https://invideo.io/make/add-text-to-gif/',
        },
      ],
    },
    tr: {
      links: [
        {
          name: 'Online Video Editor',
          link: 'https://invideo.io/make/online-video-editor/',
        },
        {
          name: 'Video Collage Maker',
          link: 'https://invideo.io/make/video-collage-maker/',
        },
        {
          name: 'Picture Video Maker',
          link: 'https://invideo.io/make/picture-video-maker/',
        },
        {
          name: 'Instagram Video Editor',
          link: 'https://invideo.io/make/instagram-video-editor/',
        },
        {
          name: 'Online Video Maker',
          link: 'https://invideo.io/make/video-maker/',
        },
        {
          name: 'Facebook Video Editor',
          link: 'https://invideo.io/make/facebook-video-editor/',
        },
        {
          name: 'Add Music to Video',
          link: 'https://invideo.io/make/add-music-to-video-online/',
        },
        {
          name: 'Add Text to GIF',
          link: 'https://invideo.io/make/add-text-to-gif/',
        },
      ],
    },
  },
  kizoa: {
    en: {
      links: [
        {
          name: 'Movie Maker',
          link: 'https://www.kizoa.com/Movie-Maker',
        },
        {
          name: 'SlideShow Maker',
          link: 'https://www.kizoa.com/Slideshow-Maker',
        },
        {
          name: 'Video Editor',
          link: 'https://www.kizoa.com/Video-Editor',
        },
        {
          name: '4K Video Editor Movie Maker',
          link: 'https://www.kizoa.com/4K-Video-Editor-Movie-Maker',
        },
        {
          name: 'Collage Maker',
          link: 'https://www.kizoa.com/Collage-Maker',
        },
      ],
    },
    fr: {
      links: [
        {
          name: 'Free Collage Maker',
          link: 'https://www.kizoa.fr/Creer-Collage-Montage-Photo',
        },
        {
          name: 'Free Online Movie Maker',
          link: 'https://www.kizoa.fr/creer-diaporama',
        },
        {
          name: 'Movie Maker',
          link: 'https://www.kizoa.fr/creer-film-montage-personnel',
        },
      ],
    },
    es: {
      links: [
        {
          name: 'Editar Videos',
          link: 'https://www.kizoa.es/Editar-Videos',
        },
        {
          name: 'Crear Collage Fotomontaje',
          link: 'https://www.kizoa.es/Crear-Collage-Fotomontaje',
        },
        {
          name: 'Movie Maker Online',
          link: 'https://www.kizoa.es/Movie-Maker-Online',
        },
        {
          name: 'Crear Video Montaje Edicion',
          link: 'https://www.kizoa.es/crear-video-montaje-edicion',
        },
      ],
    },
    tr: {
      links: [
        {
          name: 'Create and Download GIFs',
          link: 'https://tr.kizoa.com/hareketli-GIFler',
        },
        {
          name: 'Online 4K Video Editor',
          link: 'https://tr.kizoa.com/4K-Video-Film-Duzenleme-Programi',
        },
        {
          name: 'Collage Maker',
          link: 'https://tr.kizoa.com/kolaj-yapma',
        },
        {
          name: 'Online Movie Maker',
          link: 'https://tr.kizoa.com/slayt-gosterisi-video-yapma-programi',
        },
      ],
    },
  },
};

export { footerExploreLinks };
